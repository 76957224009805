(function () {

	'use-strict'

	window.constant = {

		VERSION:			'3.10.10',   
		VERSIONAPP:			'1.0.38',
		CONSULPAGESIZE:		50,
		PAGESIZE:			25,
		APPURL:             '#/comunidades-movil',
        APPURLSOFFLINE:		["/","/comunidades-offline", "/cursos-offline", "/ruta-offline" ,"/scorm-offline","/registro"],
    	HABILITAR_SOCKET:   false,
        APIURL:             'https://prelib.solucioneshumantalent.com/suite/index.php/',
        BASEURL:            'https://prelib.solucioneshumantalent.com/suite/',
        SHAREURL:           'https://prelib.solucioneshumantalent.com/suite/compartir/',
        SOCKET:             'https://prelib.solucioneshumantalent.com:8082',
        LANDINGURL:         'https://demo.solucioneshumantalent.com',
        URL_REDIRECT_AFTER_LOGOUT:	'https://demo.solucioneshumantalent.com',
        THEME:              'nom035', //Clientes disponibles [default,aforebanamex, bmv, grupomexico, nafin, universidad3m]
		URL_CLIMA_ORGANIZACIONAL:'/evaluacion-clima-organizacional',
		BASERECURSOSURL:	'https://prelib.solucioneshumantalent.com/suite/',		
        PWA_HABILITAR:  false,
		FIREBASE_DICCI: "",
		FIREBASE_LOGRO: "",
		FIREBASE_SCORE: "",
		FIREBASE_WEBIN: "",
		FIREBASE_CONFIG : {
			apiKey: "",
			authDomain: "",
			databaseURL: "",
			projectId: "",
			storageBucket: "",
			messagingSenderId: "",
			appId: ""
		},
		URL_THEME : {
			logo:           '',
			style_css:      '',
			style_js:       '',
			img_evaluacion: '',
            img_scorm: 		'',
            img_encuesta: 	'',
            img_actividad: 	'',
            img_presencial: ''
		},

/**
		 * Indica si la pagina debe recargarse cuando dan clic en los botones Atras/Adelante del navegador.
		 */
		BAND_RELOAD: true,

        /*
         *  URL Correspondiente a la ubicación de los recursos gráficos de la plataforma.
         */

        BASERECURSOSURL:    'https://prelib.solucioneshumantalent.com/suite/',

		/*
         *	Modulo Correspondiente a CONSULTAS
         */

		CONSULTAS:	{

        /*
         *	Definicion de etiquetas de estatus correspondientes a las consultas.
         */

			ESTATUS:	{

				ETIQUETAS:
					[
						'Abierto', 			// 0
						'En progreso', 		// 1
						'Indefinido', 		// 2
						'Reabierto', 		// 3
						'Resuelto', 		// 4
						'Cerrada', 			// 5
					]

			},

			CONSTANTES: {
				CONSULTAS_ESTATUS_ABIERTO:		0,
				CONSULTAS_ESTATUS_ENPROGRESO:	1,
				CONSULTAS_ESTATUS_INDEFINIDO:	2,
				CONSULTAS_ESTATUS_REABIERTO:	3,
				CONSULTAS_ESTATUS_RESUELTO:		4,
				CONSULTAS_ESTATUS_CERRADO: 		5
			}

		},

		/*
		 *	Modulo Correspondiente a Learning Managment System (LMS)
		 */

		LMS:	{

			/*
             *	Submodulo Correspondiente a Learning Managment System (LMS)
             */

			CURSOS:	{

				/*
                 *	Definicion de etiquetas de estatus correspondientes a estatus de los objetos de aprendizaje.
                 */

				ESTATUS:
					[
						'Programado', 			// 0
						'En Curso', 			// 1
						'Cancelado', 			// 2
						'Reabierto', 			// 3
						'Cerrado Completo', 	// 4
						'Cerrado Incompleto', 	// 5
						'Preinscrito', 			// 6
						'Inscrito', 			// 7
						'Aprobado', 			// 8
						'Reprobado', 			// 9
						'Visitado', 			// 10
						'No Asistio', 			// 11
						'Baja'					// 12
					],

				VALORES_DEFAULT:{
					DURACION_HORAS_CURSO: 			10,
					CAPACIDAD_ALUMNOS_POR_CLASE: 	30,
					PATH_IMAGEN_RUTA_APRENDIZAJE: 	'img/comunidades/comunidades/default_comunidad.png'
				}

			}
		},

		/*
		 *	Constantes definidas para modulo logros y reconocimientos
		 */

		LOGROS: {

			CONSTANTES:{

			    RED_SOCIAL_FACEBOOK:                                'FACEBOOK',
                RED_SOCIAL_TWITTER:                                 'TWITTER',
                RED_SOCIAL_LINKEDIN:                                'LINKEDIN',

				TABLA_PROPIETARIO_FACEBOOK_COMPARTIR_CURSO:    		'lms_ra',
				TABLA_PROPIETARIO_TWITTER_COMPARTIR_CURSO:      	'lms_ra',
				TABLA_PROPIETARIO_LINKEDIN_COMPARTIR_CURSO:     	'lms_ra',

				TABLA_PROPIETARIO_FACEBOOK_COMPARTIR_EVENTO:   		'calendario',
				TABLA_PROPIETARIO_TWITTER_COMPARTIR_EVENTO:    		'calendario',
				TABLA_PROPIETARIO_LINKEDIN_COMPARTIR_EVENTO:    	'calendario',

				TABLA_PROPIETARIO_FACEBOOK_COMPARTIR_NOTICIA:    	'noticias',
				TABLA_PROPIETARIO_TWITTER_COMPARTIR_NOTICIA:      	'noticias',
				TABLA_PROPIETARIO_LINKEDIN_COMPARTIR_NOTICIA:     	'noticias',

				TABLA_PROPIETARIO_FACEBOOK_COMPARTIR_COMUNIDAD: 	'comunidades',
				TABLA_PROPIETARIO_TWITTER_COMPARTIR_COMUNIDAD:  	'comunidades',
				TABLA_PROPIETARIO_LINKEDIN_COMPARTIR_COMUNIDAD:  	'comunidades',

				TABLA_PROPIETARIO_LMS_APROBAR_CURSO:            	'lms_ra',
				TABLA_PROPIETARIO_FOROS_PARTICIPAR_EN_FORO:     	'foros_preguntas',
				TABLA_PROPIETARIO_COMUNIDAD_MURO_LIKE_PUBLICACION:	'comunidades_muro_publicaciones'

			}

		},

		/*
		 *	Constantes definidas para modulo de busqueda-
		 */


		BUSQUEDAS: {

			CONSTANTES:{

				BUSQUEDA_COMUNIDADES_PROPIETARIO:               'comunidades',
				BUSQUEDA_COMUNIDADES_TIPO_ELEMENTO:             'Comunidad',
				BUSQUEDA_NOTICIAS_PROPIETARIO:                  'noticias',
				BUSQUEDA_NOTICIAS_TIPO_ELEMENTO:                'Noticia',
				BUSQUEDA_FOROS_PREGUNTAS_PROPIETARIO:           'foros_preguntas',
				BUSQUEDA_FOROS_PREGUNTAS_TIPO_ELEMENTO:         'Foro',
				BUSQUEDA_CALENDARIO_PROPIETARIO:                'calendario',
				BUSQUEDA_CALENDARIO_TIPO_ELEMENTO:              'Evento',
				BUSQUEDA_ELEMENTOS_ASOCIADOS_PROPIETARIO:       'lms_ra',
				BUSQUEDA_ELEMENTOS_ASOCIADOS_TIPO_ELEMENTO:     'Elementos',
				BUSQUEDA_DOCUMENTOS_PROPIETARIO:                'documentos',
				BUSQUEDA_DOCUMENTOS_TIPO_ELEMENTO:              'Documento',
				BUSQUEDA_EVALUACIONES_DINAMICAS_PROPIETARIO:    'evaluacion_dinamica',
				BUSQUEDA_EVALUACIONES_DINAMICAS_TIPO_ELEMENTO:  'Evaluacion Dinamica'

			}

		},

		EVALUACIONES_DINAMICAS: {

			/*
			 *	Definicion de constantes de estatus correspondientes a estatus de los objetos de aprendizaje.
			 */

			CONSTANTES:{
				PREGUNTAS_TIPO_RESPUESTA_SI_NO:					1,
				PREGUNTAS_TIPO_RESPUESTA_UNICA:					2,
				PREGUNTAS_TIPO_RESPUESTA_MULTIPLE:				3,
				PREGUNTAS_TIPO_RESPUESTA_LISTA_DESPLEGABLE:		4,
				PREGUNTAS_TIPO_RESPUESTA_RELACIONAR_COLUMNAS:	5,
				PREGUNTAS_TIPO_RESPUESTA_PORCENTAJE:			6,
				PREGUNTAS_TIPO_RESPUESTA_PUNTUACION:			7,
				PREGUNTAS_TIPO_RESPUESTA_ABIERTA:				8,
				PREGUNTAS_TIPO_RESPUESTA_ORDENAR_RESPUESTA:		9,
				PREGUNTAS_TIPO_RESPUESTA_CALIFICAR_NIVELES:		10
			}

		}

	};


    /*
     * configuracionEmpresa - Variable utilitaria ara
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2018-10-10
     * @param   void
     * @return  void
     */

    window.configuracionEmpresa =        {
        logotipo_empresa_ruta_imagen:   constant.BASERECURSOSURL + '/img/comunidades/comunidades/default_comunidad.png',
        empresa_descripcion:			'',
        look_and_feel:                  'humantalent',
        empresa:                        ''
    };


	/*
     * add_user_action - Función utilitaria. Registra la accion de un usuario en el modulo de logros y reconocimientos.
     *
     *
     * En mascara la aplicación con el elemento {{partial "loading"}}. Utilizado al ejecutar alguna
     * acción que requiera espera que el usuario espere que se finalize alguna acción.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2018-10-10
     * @param   void
     * @return  void
     */

	window.add_user_action = function( id_usuario, id_accion, nombre_usuario, tabla_propietario = '', propietario_k = null, red_social_compartido = null ){

		$.ajax({
			url:            constant.APIURL + 'ranking/ranking/firebasealternativeservice',
			contentType:    'application/x-www-form-urlencoded',
			xhrFields:      { withCredentials: true },
			type:           'POST',
			data:{
				'method':'add_action',
				'data':{
					'usuario_k':		    id_usuario,
					'accion_k':		        id_accion,
					'nombre_usuario':	    nombre_usuario,
                    'red_social_compartido':red_social_compartido,
					'tabla_propietario':    tabla_propietario,
					'propietario_k':	    propietario_k
				}
			},
			success:function(data){
				console.log(data);
			}
		});
	};


	/*
     * mask - Función utilitaria. Enmascara la aplicación con el elemento {{partial "loading"}}.
     *
     *
     * En mascara la aplicación con el elemento {{partial "loading"}}. Utilizado al ejecutar alguna
     * acción que requiera espera que el usuario espere que se finalize alguna acción.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2018-10-10
     * @param   void
     * @return  void
     */

	window.mask 		= function(){ $('#loading').fadeIn('slow'); };

	/*
     * mask - Función utilitaria. Desenmascara la aplicación con el elemento {{partial "loading"}}.
     *
     *
     * En mascara la aplicación con el elemento {{partial "loading"}}.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2018-10-10
     * @param   void
     * @return  void
     */

	window.unmask		= function(){ $('#loading').fadeOut('slow'); };



	/*
     * showModalError - Función utilitaria. Muestra un mensaje a usuario.
     *
     *
     * Función utilitaria. Muestra al usuario un mensaje, tomando los atributos enviados.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2018-10-10
     * @param   object params_object Objeto con los detalle del mensaje a mostrar.
     * @return  void
     */

	window.showModal 	= function( params_object ){

		$('#modal-avisos .modal-header').text( params_object.titulo );
		$('#modal-avisos .modal-body'  ).html( params_object.mensaje );
		$('#modal-avisos').modal('show');

	};


	/*
     * solveResourceURL - Función utilitaria. Formatea la URL de imagen enviada a una URL absoluta.
     *
     *
	 * Sí la URL envaida no contiene un formato de URL Absoluta hacia BASERECURSOSURL, retorrna la yuxtaposición de  BASERECURSOSURL y la URL de imagen enviada.
	 * En caso contrario retorna la URL son cambio alguno.
	 *
	 *
     * Función utilitaria. Muestra un mensaje genérico de error al usuario.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2018-10-10
     * @param   string image_url Path/Ruta de la ubicación de un recurso.
     * @return  string URL en formato de URL absoluta.
     */

	window.solveResourceURL = function( resouce_url ) {
		if( !resouce_url ){ resouce_url = ''; }
		if ( resouce_url.indexOf(constant.BASERECURSOSURL) == -1 ){
			if(resouce_url.indexOf('http')!=0){
				resouce_url = constant.BASERECURSOSURL + resouce_url;
			}
		}
		return resouce_url;
	};

	/*
     * solveImageURL - Función utilitaria. Formatea la URL de imagen enviada a una URL absoluta.
     *
     *
	 * Sí la URL envaida no contiene un formato de URL Absoluta hacia BASERECURSOSURL, retorrna la yuxtaposición de  BASERECURSOSURL y la URL de imagen enviada.
	 * En caso contrario retorna la URL son cambio alguno.
	 *
	 *
     * Función utilitaria. Muestra un mensaje genérico de error al usuario.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2018-10-10
     * @param   string image_url Path/Ruta de la ubicación de un recurso.
     * @return  string URL en formato de URL absoluta.
     */


	window.solveImageURL= function( image_url ){

		return solveResourceURL( image_url );

	}

	/*
     * showModalError - Función utilitaria. Muestra un mensaje genérico de error al usuario.
     *
     *
     * Función utilitaria. Muestra un mensaje genérico de error al usuario.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2018-10-10
     * @param   void
     * @return  void
     */

	window.showModalError = function(){

		showModal( { titulo: 'Error en sistema.', mensaje : 'Ups! acaba de suceder algo inesperado. Por favor intente nuevamente en otro momento.' } );

	};


	/*
     * isFunction - Función utilitaria. Válida si el parametro enviado es una función.
     *
     *
     * Función utilitaria. si el parametro enviado es una función de javascript, devuelve true, en caso contrario false.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2018-10-10
     * @param   mixed parameter_mixed Parametro a evaluar.
     * @return  bool Si el parametro enviado es una función de javascript, devuelve true, en caso contrario false.
     */

	window.isFunction = function( parameter_mixed ){

		return ( (typeof parameter_mixed) == "function" )?true:false;

	};

	/*
    * validateDictionary - Función utilitaria. Válida si el texto enviado contiene palabras restringidas.
    *
    *
    * Función utilitaria. Si el texto introducido contiene palabras restringidas envia mensaje alerta a usuario indicando dichas palabras.
    *
    *
    * @author  Juan Carlos Santana Arana
    *                  juancarlos.santana@thehumantalent.com  maurilio.cruz.m@gmail.com
    *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
    *              Tel. celular (55) 6702 6252
    *
    * @date    2019-08-14
    * @param   str Parametro a evaluar.
    * @return  bool Si el parametro enviado es una función de javascript, devuelve true, en caso contrario false.
    */
	window.validateDictionary = function(str){
		let palabrasEnTexto = str.toLowerCase().split(" ");
		let arrayCoincidencias = window.searchArrayIntoOtherArray(palabrasEnTexto,window.diccionario);
		if(arrayCoincidencias.length){
			showModal( { titulo: 'Alerta!', mensaje : 'El mensaje que intenta enviar contiene las siguientes palabras restringidas:<br><br> <ul><li class="text-capitalize">' + arrayCoincidencias.join().replace(/,/gi,"</li><li class='text-capitalize'>") + "</li></ul> <div class='text-center'>Por favor edite su mensaje y vuelva a enviar</div>" } );
			return false;
		}
		else
			return true;
	};


	/*
    * searchArrayIntoOtherArray - Función utilitaria. Busca las coincidencias de un array a otro array.
    *
    *
    * Función utilitaria. Busca los valores de primary array dentro de secondary array, en caso de no encontrase se elimina el indice de primary array.
    *
    *
    * @author  Juan Carlos Santana Arana
    *                  juancarlos.santana@thehumantalent.com  maurilio.cruz.m@gmail.com
    *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
    *              Tel. celular (55) 6702 6252
    *
    * @date    2019-08-15
    * @param   str Parametro a evaluar.
    * @return  bool Si el parametro enviado es una función de javascript, devuelve true, en caso contrario false.*/
	window.searchArrayIntoOtherArray = function(primaryArray, secondaryArray){
		return  [...new Set(primaryArray.filter(value => -1 !== secondaryArray.indexOf(value)))];
	}

	/*
     * translate
     *
     *
     *
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date
     * @param
     * @return
     */

	window.translate = function(path, etiqueta){

		this.validateLabel = function(path){

			/*Tomar variable global de idioma*/
			var idioma = window.idiomaGlobal;

			if(window.isEmpty(path)){
				return false;
			}

			/*Separar por puntos*/
			var t = path.split('.');

			for(var i=0; i<t.length; i++){

				/*Preguntar si exite la propiedad a la cual quiero acceder*/
				if( idioma.hasOwnProperty(t[i]) ){
					/*Si existe accedo a ella y continuo entrando mas a fondo de la estructura*/
					idioma = idioma[t[i]];
				}else{
					/*Si no existe... regreso false -> no hay un match en el idioma*/
					return false;
				}

			}

			/*Si la propiedad existe y es una cadena -> he encontrado la etiqueta que quiero*/
			if(typeof(idioma)=='string'){
				return idioma;
			}else{
				/*Si la propiedad es de otro tipo (object por ejemplo) -> no es una etiqueta valida*/
				return false;
			}
		};

		var et = window.validateLabel(path);
		return  et ? et : etiqueta;

	}

	window.dateFormat = function(day,month,year){
		let fecha = "";
		switch(window.idiomaPrincipal){
			case "es-MX":
				// Ejemplo => 30 de Enero del 2021
				fecha = day + " de " + month + " del " + year;
				break;

			case "en-US":
				// Ejemplo => January 30, 2021
				fecha = month + " " + day + ", " + year;
				break;

			default:
				fecha = day + "/" + month + "/" + year;
				break;
		}
		return fecha;
	}

	/*
     * translate
     *
     *
     *
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date
     * @param
     * @return
     */
	window.isEmpty = function(n){
		return !(!!n ? typeof n === 'object' ? Array.isArray(n) ? !!n.length : !!Object.keys(n).length : true : false);
	}

})();
